import { mutateUsers } from "@/api/users/useUsersQuery";
import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import customAlert from "@/features/ui/alert/alert";
import { DepartmentAutoComplete } from "@/features/ui/autoComplete/department/department-autoComplete";
import { EquipmentAutoComplete } from "@/features/ui/autoComplete/equipment/equipment-autoComplete";
import { LocationAutoComplete } from "@/features/ui/autoComplete/location/location-autoComplate";
import { handleErrorResponse } from "@/utils/errorMessage";
import { validateFormValues } from "@/utils/validation";
import styled from "@emotion/styled";
import { Box, Button, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiUsersPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface UsersCreateFormProps {
  code?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

const isDepartmentInformIncluded = customFunctions.ADD_USER_DEPARTMENT_INFORM;

export function UsersCreateForm(params: UsersCreateFormProps) {
  const { code, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const { t } = useTranslation();

  const form = useForm({
    initialValues: {
      code: code ?? undefined,
      name: undefined,
      username: undefined,
      password: undefined,
      equipmentCode: undefined,
      departmentCode: undefined,
      printerCode: undefined,
      locationCode: undefined,
      userType: undefined,
    },
  });

  const queryClient = useQueryClient();
  const { mutate: createMutate } = useMutation(
    (params: MasterApiUsersPostRequest) =>
      mutateUsers.create(params).mutationFn(params as MasterApiUsersPostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
        customAlert(
          `${t("message.createSuccess", { item: `${t("user.user")}` })}`,
          t("common.createSuccess"),
          "green",
        );
        closeModal(form.values);
      },
      onError: (error: any) => {
        customAlert(
          handleErrorResponse({ error, errorContext: `${t("user.code")}나 ${t("user.username")}` }),
          `${t("common.createFail", { item: `${t("user.user")}` })}`,
          "red",
        );
      },
    },
  );

  const validatePassword = (password: string): boolean => {
    const minLength = 8;
    const hasLetter = /[a-zA-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return (
      password.length >= minLength &&
      ((hasLetter && hasNumber) || (hasLetter && hasSpecialChar) || (hasNumber && hasSpecialChar))
    );
  };

  const onSubmit = async () => {
    const { password } = form.values;

    if (password && !validatePassword(password)) {
      customAlert(
        "비밀번호는 최소 8자리 이상이어야 하며, 문자, 숫자, 특수문자 중 두 종류 이상을 포함해야 합니다.",
        "유효성 검사 실패",
        "red",
      );
      return;
    }

    try {
      createMutate({
        authSignupPostRequest: {
          code: code ?? form.values.code!,
          name: form.values.name!,
          username: form.values.username!,
          password: form.values.password,
          equipmentCode: form.values.equipmentCode,
          ...(isDepartmentInformIncluded ? { departmentCode: form.values.departmentCode } : {}),
          printerCode: form.values.printerCode,
          locationCode: form.values.locationCode,
          userType: form.values.userType,
        },
      });
    } catch (error) {
      customAlert(
        handleErrorResponse({ error, errorContext: `${t("user.code")}나 ${t("user.username")}` }),
        `${t("common.createFail", { item: `${t("user.user")}` })}`,
        "red",
      );
    }
  };

  return (
    <Box maw={320} mx="auto">
      <Form>
        <ModalFieldSet>
          <TextInput
            label={t("user.code")}
            placeholder={t("placeholder.input", { item: `${t("user.code")}` })}
            withAsterisk
            {...form.getInputProps("code")}
          />
          <TextInput
            label={t("user.name")}
            placeholder={t("placeholder.input", { item: `${t("user.name")}` })}
            withAsterisk
            {...form.getInputProps("name")}
          />
          <TextInput
            label={t("user.username")}
            placeholder={t("placeholder.input", { item: `${t("user.username")}` })}
            withAsterisk
            {...form.getInputProps("username")}
          />
          <TextInput
            // type="number"
            label={t("user.password")}
            placeholder={t("placeholder.input", { item: `${t("user.password")}` })}
            {...form.getInputProps("password")}
            error={
              form.isDirty("password") && form.values.password !== undefined
                ? validatePassword(form.values.password)
                  ? t("common.toSave")
                  : t("common.passwordRule")
                : ""
            }
          />
          <EquipmentAutoComplete
            label={t("equipment.code")}
            placeholder={t("placeholder.select", { item: `${t("equipment.code")}` })}
            {...form.getInputProps("equipmentCode")}
          />
          <LocationAutoComplete
            label={t("location.code")}
            placeholder={t("placeholder.select", { item: `${t("location.code")}` })}
            {...form.getInputProps("locationCode")}
          />
          {isDepartmentInformIncluded && (
            <DepartmentAutoComplete
              label={t("department.code")}
              placeholder={t("placeholder.select", { item: `${t("department.code")}` })}
              {...form.getInputProps("departmentCode")}
            />
          )}
          <TextInput
            label={t("user.printer")}
            {...form.getInputProps("printerCode")}
            placeholder={t("placeholder.input", { item: `${t("user.printer")}` })}
            {...form.getInputProps("printerCode")}
          />
          {customFunctions.ADD_USER_TYPE && (
            <Select
              label={t("user.userType")}
              data={[
                { value: "FIELD_WORKER", label: "현장 근무자" },
                { value: "MANAGER", label: "관리자" },
                { value: "OFFICE_WORKER", label: "사무직 근무자" },
                { value: "EQUIPMENT_ASSIGNER", label: "장비 지정자" },
              ]}
              {...form.getInputProps("userType")}
            />
          )}
          <ModalConfirmBtnBox>
            <Button color="gray" onClick={closeModal}>
              {t("common.cancel")}
            </Button>
            <Button
              disabled={validateFormValues({
                pageType: "users",
                values: form.values,
              })}
              onClick={autoCompleteOnSubmit ? () => autoCompleteOnSubmit(form.values) : onSubmit}
            >
              {t("common.create", { item: `${t("user.user")}` })}
            </Button>
          </ModalConfirmBtnBox>
        </ModalFieldSet>
      </Form>
    </Box>
  );
}

const Form = styled.form`
  width: 100%;
  height: 100%;
`;

const ModalFieldSet = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalConfirmBtnBox = styled.div`
  padding: 24px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
