import { workLogs } from "@/api/workLogs/useWorksLogsQuery";
import { ItemsDetailForm } from "@/features/item/components/form/ItemsDetailForm";
import { LocationDetailForm } from "@/features/location/detail/LocationDetailForm";
import { useModal } from "@/features/modal/ModalStackManager";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { UsersDetailForm } from "@/features/users/components/form/UsersDetailForm";
import {
  NoDataTr,
  PaginationWrapper,
  TabBodyTable,
  TabBodyTitle,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  WorkTabBodyContainer,
} from "@/features/work/WorkView/WorkProductionHistory";
import { setDateFormatStringWithTime } from "@/utils/dateTimeUtil";
import { setToLocaleString } from "@/utils/unitMark";
import { setWorkStatus, trackingStatusColor } from "@/utils/workStatus";
import { Badge, Flex, Pagination, Text } from "@mantine/core";
import {
  WorkLogsGet200ResponseRowsInner,
  WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { WorkViewProps } from "./WorkView";
export const WorkStatusHistory = ({ workId }: WorkViewProps) => {
  const [activePage, setActivePage] = useState<number>(1);
  const { t } = useTranslation();

  const { data: workLogsData } = useQuery(
    workLogs.get({
      query: {
        $and: [
          { workId: { $eq: workId ?? 0 } },
          {
            workLogType: [
              WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_START,
              WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_END,
              WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_PAUSE,
              WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.TIME_TRACKING_RESUME,
            ],
          },
        ],
      },
      sort: "-id",
      page: activePage,
      populate: [
        "itemUnit",
        "defectName",
        "alreadyCanceled",
        "creatorUser",
        "downtimeReasonName",
        "getEquipment",
      ],
    }),
  );

  const rows: readonly WorkLogsGet200ResponseRowsInner[] = workLogsData?.data.rows ?? [];

  const { openModal } = useModal();

  return (
    <WorkTabBodyContainer>
      <TabBodyTitle>
        <Text fz="xl" fw={500}>
          {t("workTab.statusHistory")}
        </Text>
        <Text fz="xs" fw={400}>
          {t("statusHistory.description")}
        </Text>
      </TabBodyTitle>
      <TabBodyTable>
        <Thead>
          <Tr>
            <Th width={80}>{t("workLog.id")}</Th>
            <Th width={120}>{t("workLog.createdAt")}</Th>
            <Th width={140}>{t("workLog.type")}</Th>
            <Th width={120}>{t("inventory.quantity")}</Th>
            <Th width={200}>{t("productionHistory.bomItem")}</Th>
            <Th width={200}>{t("productionHistory.bomLot")}</Th>
            <Th width={100}>{t("workLog.location")}</Th>
            <Th width={100}>{t("productionHistory.logNum")}</Th>
            <Th>{t("workLog.worker")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rows.length ? (
            rows.map((workLog) => (
              <Tr>
                <Td width={80} style={{ justifyContent: "flex-end" }}>
                  {workLog.id}
                </Td>
                <Td width={120}>{setDateFormatStringWithTime(workLog.createdAt)}</Td>
                <Td width={140}>
                  <Badge
                    color={
                      trackingStatusColor[workLog.workLogType as keyof typeof trackingStatusColor]
                    }
                  >
                    {setWorkStatus(workLog.workLogType)}
                  </Badge>
                </Td>
                <Td width={120} style={{ justifyContent: "flex-end" }}>
                  {workLog.quantity !== null ? setToLocaleString(workLog.quantity) : "0"}{" "}
                  {workLog.unitText}
                </Td>
                <Td width={200}>
                  <DetailLink
                    onClick={() =>
                      openModal(
                        <ItemsDetailForm
                          itemCode={
                            workLog.logData?.incoming?.itemCode ??
                            workLog.logData?.outgoing?.itemCode
                          }
                        />,
                        null,
                        `${t("common.detail", { item: `${t("item.item")}` })}`,
                      )
                    }
                  >
                    {workLog.logData
                      ? workLog.logData?.incoming
                        ? workLog.logData?.incoming?.itemData?.name
                        : workLog.logData?.outgoing?.itemData?.name
                      : ""}
                  </DetailLink>
                </Td>
                <Td width={200}>
                  {workLog.logData ? (
                    workLog.logData?.incoming ? (
                      <Flex direction="column">
                        <Text>id: {workLog.logData?.incoming?.lotData?.id}</Text>
                        <Text>
                          {t("lot.name")} : {workLog.logData?.incoming?.lotData?.name}
                        </Text>
                        <Text>
                          {t("lot.expiration")} : {workLog.logData?.incoming?.lotData?.expiration}
                        </Text>
                      </Flex>
                    ) : (
                      <Flex direction="column">
                        <Text>id: {workLog.logData?.outgoing?.lotData?.id}</Text>
                        <Text>
                          {t("lot.name")} : {workLog.logData?.outgoing?.lotData?.name}
                        </Text>
                        <Text>
                          {t("lot.expiration")} : {workLog.logData?.outgoing?.lotData?.expiration}
                        </Text>
                      </Flex>
                    )
                  ) : null}
                </Td>
                <Td width={100}>
                  <DetailLink
                    onClick={() =>
                      openModal(
                        <LocationDetailForm
                          locationCode={
                            workLog.logData?.incoming?.locationCode ??
                            workLog.logData?.outgoing?.locationCode
                          }
                        />,
                        null,
                        "",
                      )
                    }
                  >
                    {workLog.logData
                      ? workLog.logData?.incoming
                        ? workLog.logData?.incoming.locationCode
                        : workLog.logData?.outgoing?.locationCode
                      : ""}
                  </DetailLink>
                </Td>
                <Td width={100} style={{ justifyContent: "flex-end" }}>
                  {workLog.parentWorkLogId}
                </Td>
                <Td>
                  <DetailLink
                    onClick={() =>
                      openModal(<UsersDetailForm UserCode={workLog.creatorUserCode} />, null, "")
                    }
                  >
                    {workLog.creatorUserCode}
                  </DetailLink>
                </Td>
              </Tr>
            ))
          ) : (
            <NoDataTr>
              <Text>{t("statusHistory.notFound")}</Text>
            </NoDataTr>
          )}
        </Tbody>
      </TabBodyTable>
      <PaginationWrapper>
        <Pagination
          onChange={setActivePage}
          value={activePage}
          total={workLogsData?.data?.totalPages ?? 0}
          size="lg"
          radius="sm"
        />
      </PaginationWrapper>
    </WorkTabBodyContainer>
  );
};
