import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { FileImportForm } from "@/features/ui/form/file";
import { BASE_URL } from "@/instance/axios";
import { getUserCodeByUser } from "@/utils/checkData";
import { Flex, Menu, ScrollArea, Text } from "@mantine/core";
import { useResizeObserver } from "@mantine/hooks";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";
import axios from "axios";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

type LinkType = {
  label: string;
  links?: (Link | NestedLink)[];
  link?: string;
  visible?: boolean; // 추가
  target?: string;
}[];

type Link = {
  link: string;
  label: string;
  action?: boolean;
  actionKey?: "download" | "upload" | "forward" | "reverce";
  visible?: boolean; // 추가
  target?: string;
};

type NestedLink = {
  label: string;
  links: (Link | NestedLink)[];
  action?: boolean;
  actionKey?: "download" | "upload" | "forward" | "reverce";
  visible?: boolean; // 추가
  target?: string;
};

// 메뉴 Item의 최대 높이 설정. 높이가 MAX_HEIGHT보다 크면 스크롤바를 표시하기 위함
const MAX_HEIGHT = 400; // 25rem

const userCode = getUserCodeByUser();

const RenderSubMenuItems = (items: (Link | NestedLink)[]) => {
  const { openModal } = useModal();

  const handleDownLoad = () => {
    const today = new Date();
    const formattedDate = today.toLocaleDateString("ko-KR");
    // 파일 다운로드 API
    axios
      .get(`${BASE_URL}/interport/export`, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("authToken"),
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `기준정보_${formattedDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };

  const handleUpload = () => {
    openModal(<FileImportForm />, null, "기준정보 업로드");
  };

  return items
    .filter((item) => !!item.visible) // visible이 false인 경우 필터링
    .map((item) => {
      if ("links" in item) {
        return (
          <Menu.Item component="div" key={item.label}>
            <Flex justify="space-between" align="center">
              <Menu shadow="md" trigger="hover" position="right" withArrow withinPortal>
                <Menu.Target>
                  <Flex
                    justify="space-between"
                    align="center"
                    style={{ cursor: "pointer", width: "100%" }}
                  >
                    <Text>{t(item.label)}</Text>
                    <IconChevronRight size="0.9rem" stroke={1.5} />
                  </Flex>
                </Menu.Target>
                <Menu.Dropdown>{RenderSubMenuItems(item.links)}</Menu.Dropdown>
              </Menu>
            </Flex>
          </Menu.Item>
        );
      }
      return (
        <Menu.Item
          key={item.label}
          component={RouterLink}
          to={item.action ? "#" : item.link}
          target={item.target}
          onClick={
            item.action
              ? item.actionKey === "download"
                ? handleDownLoad
                : item.actionKey === "upload"
                  ? handleUpload
                  : undefined
              : undefined
          }
          color={item.action ? "indigo" : ""}
        >
          {t(item.label)}
        </Menu.Item>
      );
    });
};

const renderMainMenuItems = (items: LinkType) => {
  return items
    .filter((item) => !!item.visible) // visible이 false인 경우 필터링
    .map((item) => (
      <Menu key={item.label} shadow="md" trigger="hover" withArrow withinPortal>
        <Menu.Target>
          {item.link ? (
            <RouterLink to={item.link} style={{ textDecoration: "none", color: "inherit" }}>
              <Flex align="center" style={{ cursor: "pointer" }}>
                <Text>{t(item.label)}</Text>
              </Flex>
            </RouterLink>
          ) : (
            <Flex align="center" style={{ cursor: "pointer" }}>
              <Text>{t(item.label)}</Text>
              {item.links && <IconChevronDown size="0.9rem" stroke={1.5} />}
            </Flex>
          )}
        </Menu.Target>
        {item.links && (
          <Menu.Dropdown>
            <DynamicHeightScrollArea>{RenderSubMenuItems(item.links)}</DynamicHeightScrollArea>
          </Menu.Dropdown>
        )}
      </Menu>
    ));
};

const DynamicHeightScrollArea = ({ children }: { children: React.ReactNode }) => {
  const [ref, rect] = useResizeObserver(); // useResizeObserver로 ref와 rect 받아오기
  // 높이 설정: 내용이 MAX_HEIGHT보다 크면 MAX_HEIGHT, 아니면 rect.height 사용
  const calculatedHeight = rect.height > MAX_HEIGHT ? MAX_HEIGHT : rect.height;

  return (
    <ScrollArea h={calculatedHeight}>
      <Flex direction="column" ref={ref}>
        {children}
      </Flex>
    </ScrollArea>
  );
};

export const MenuTemplate = () => {
  const { t, i18n } = useTranslation();

  const links: LinkType = [
    { link: "/dashboard", label: t("menu.dashboard"), visible: true },
    {
      label: "테스트",
      visible: customFunctions.ADD_BEST_PRACTICE_TEST_MENU_SETTING,
      links: [
        {
          link: "/test-standard-info-best-practice",
          label: t("menu.standard.standard"),
          visible: true,
        },
      ],
    },
    {
      label: t("menu.system.management"),
      visible: customFunctions.ADD_SYSTEM_MANAGEMENT_MENU_SETTING,
      links: [
        { link: "/system", label: "System Access Log", visible: true },
        { link: "/system-logs", label: t("menu.system.log"), visible: true },
        { link: "/system-parameter", label: t("menu.system.parameter"), visible: true },
      ],
    },
    {
      label: t("menu.standard.standard"),
      visible: true,
      links: [
        {
          link: "/common-code",
          label: t("menu.standard.commonCode"),
          visible: customFunctions.ADD_STANDARD_INFO_COMMON_CODE_MENU_SETTING,
        },
        {
          link: "/authority",
          label: t("menu.standard.authority"),
          visible: customFunctions.ADD_STANDARD_INFO_AUTH_GROUP_MENU_SETTING,
        },
        {
          link: "/department",
          label: t("menu.standard.department"),
          visible: customFunctions.ADD_STANDARD_INFO_DEPART_CODE_MENU_SETTING,
        },
        { link: "/items", label: t("item.item"), visible: true },
        { link: "/equipment", label: t("equipment.equipment"), visible: true },
        { link: "/defects", label: t("defect.defect"), visible: true },
        { link: "/operations", label: t("operation.operation"), visible: true },
        { link: "/routing", label: t("routing.routing"), visible: true },
        { link: "/users", label: t("user.user"), visible: true },
        {
          link: "/userLogs",
          label: t("menu.standard.userLogs"),
          visible: customFunctions.ADD_USER_ACCESS_STATUS,
        },
        { link: "/site", label: t("site.site"), visible: true },
        { link: "/location", label: t("location.location"), visible: true },
        { link: "/routing-outsource", label: t("outsource.outsource"), visible: true },
        {
          link: "/ALCmodel",
          label: t("menu.standard.ALCmodel"),
          visible: customFunctions.ADD_STANDARD_INFO_ALC_MODEL_MENU_SETTING,
        },
        { link: "/mold", label: t("mold.mold"), visible: true },
        { link: "/moldStock", label: t("moldStock.moldStock"), visible: true },
        { link: "/abstract-item", label: t("abstractItem.abstractItem"), visible: true },
        { link: "/item-category", label: t("category.category"), visible: true },
        {
          link: "/partner",
          label: t("partner.partner"),
          visible: customFunctions.ADD_STANDARD_INFO_PARTNER_MENU_SETTING,
        },
        { link: "/downtimeReasons", label: t("downtimeReason.downtimeReason"), visible: true },
        {
          link: "/bom",
          label: t("menu.standard.bom"),
          visible: customFunctions.ADD_STANDARD_INFO_BOM_MENU_SETTING,
        },
        {
          link: "/bomSearch",
          label: t("menu.standard.bomSearch"),
          visible: customFunctions.ADD_STANDARD_INFO_BOM_SEARCH_MENU_SETTING,
        },
        {
          link: "/bomList",
          label: t("menu.standard.bomList"),
          visible: customFunctions.ADD_STANDARD_INFO_BOM_LIST_MENU_SETTING,
        },
        {
          link: "/document-file",
          label: t("menu.standard.file"),
          visible: customFunctions.ADD_STANDARD_INFO_FILE_MENU_SETTING,
        },
        {
          link: "/peripheral",
          label: t("menu.standard.peripheral"),
          visible: customFunctions.ADD_STANDARD_INFO_PERIPHERAL_MENU_SETTING,
        },
        {
          link: "/machine",
          label: t("menu.standard.machine"),
          visible: customFunctions.ADD_STANDARD_INFO_MACHINE_MENU_SETTING,
        },
        {
          link: "/grade",
          label: "등급",
          visible: customFunctions.ADD_STANDARD_INFO_GRADE_MENU_SETTING,
        },
        {
          link: "/#",
          label: t("menu.standard.download"),
          action: true,
          actionKey: "download",
          visible: true,
        },
        {
          link: "/#",
          label: t("menu.standard.upload"),
          action: true,
          actionKey: "upload",
          visible: true,
        },
      ],
    },
    {
      label: t("menu.production.production"),
      visible: true,
      links: [
        { link: "/work-by-equipment", label: t("menu.production.equipment"), visible: true },
        {
          link: "/work-by-production-plan",
          label: t("menu.production.productionPlan"),
          visible: true,
        },
        { link: "/work-by-row", label: t("menu.production.work"), visible: true },
        {
          link: "/operation-outsource-work-by-row",
          label: t("menu.production.outsource"),
          visible: customFunctions.ADD_SHOW_OPERATION_OUTSOURCING_WORK,
        },
        { link: "/work-log", label: t("menu.production.workLog"), visible: true },
        {
          link: "/productionperformance",
          label: t("menu.production.performance"),
          visible: customFunctions.ADD_PERFORMANCE_MENU_SETTING,
        },
        {
          link: "/productionperformanceOutsourcing",
          label: t("menu.production.outsourcePerformance"),
          visible: customFunctions.ADD_PERFORMANCE_OUTSOURCING_MENU_SETTING,
        },
        // { link: "/downtimeReasons-log", label: "정지유형별 통계", visible: customFunctions.ADD_DOWNTIME_REASON_LOG_MENU_SETTING },
        {
          link: "/lotForward",
          label: t("menu.production.lotForward"),
          visible: true,
        },
        {
          link: "/lotReverce",
          label: t("menu.production.lotReverce"),
          visible: true,
        },
      ],
    },
    {
      label: t("menu.inventory.management"),
      visible: true,
      links: [
        { link: "/inventory", label: t("menu.inventory.inventory"), visible: true },
        { link: "/ledgersAll", label: t("menu.inventory.ledger"), visible: true },
        { link: "/wipInventory", label: t("menu.inventory.wipInventory"), visible: true },
        { link: "/wmsLogs", label: t("menu.inventory.wmsLog"), visible: true },
        {
          link: "/lackSafety",
          label: t("menu.inventory.lackSafety"),
          visible: customFunctions.ADD_LACK_SAFETY_MENU_SETTING,
        },
        {
          link: "/inventoryLife",
          label: t("menu.inventory.life"),
          visible: customFunctions.ADD_INVENTORY_LIFE_MENU_SETTING,
        },
        {
          link: "/lots",
          label: t("menu.inventory.lot"),
          visible: customFunctions.ADD_LOT_MANAGEMENT,
        },
      ],
    },
    {
      label: t("menu.shipment.shipment"),
      visible: customFunctions.ADD_STORAGE_MANAGEMENT_MENU_SETTING,
      links: [
        { link: "/shipment-request", label: t("menu.shipment.request"), visible: true },
        { link: "/shipment-receipt", label: t("menu.shipment.receipt"), visible: true },
        { link: "/shipment-status", label: t("menu.shipment.status"), visible: true },
      ],
    },
    {
      label: t("menu.sales.sales"),
      visible: true,
      links: [
        {
          link: "/purchaseOrder",
          label: t("menu.sales.purchaseOrder"),
          visible: !customFunctions.ADD_PURCHASE_ORDER_ITEMS_MENU_SETTING,
        },
        {
          link: "/purchaseOrderItems",
          label: t("purchaseOrderItems.purchaseOrderItems"),
          visible: !customFunctions.ADD_PURCHASE_ORDER_ITEMS_MENU_SETTING,
        },
        {
          link: "invoiceStatement",
          label: t("invoice.invoice"),
          visible: customFunctions.ADD_INVOICE_STATEMENT_MENU_SETTING,
        },
        {
          link: "/invoiceStatementComplete",
          label: t("invoice.invoiceComplete"),
          visible: customFunctions.ADD_INVOICE_STATEMENT_MENU_SETTING,
        },
        { link: "/deliveryStatus", label: t("menu.sales.delivery"), visible: true },
        { link: "/order", label: t("menu.sales.order"), visible: true },
        { link: "/orderItems", label: t("orderItems.orderItems"), visible: true },
        {
          link: "/orderComplete",
          label: t("order.orderComplete"),
          visible: customFunctions.ADD_ORDER_COMPLETE_MENU_SETTING,
        },
        { link: "/stockPlan", label: t("stockPlan.stockPlan"), visible: true },
        { link: "/transportation", label: t("transportation.transportation"), visible: true },
      ],
    },
    {
      label: t("menu.quality.quality"),
      visible: customFunctions.ADD_QUALITY_MENU_SETTING,
      links: [
        { link: "/inspectSpecification", label: t("menu.quality.inspect"), visible: true },
        { link: "/inspectionRecords", label: t("menu.quality.inspectRecord"), visible: true },
        {
          link: "/quality/defect-statistics",
          label: t("menu.quality.defect"),
          visible: customFunctions.ADD_DEFECT_STATISTICS_MENU_SETTING,
        },
        {
          link: "/quality/work-standardList",
          label: t("menu.quality.work"),
          visible: customFunctions.ADD_WORK_STANDARD_DOCUMENT_LIST_MENU_SETTING,
        },
        // { link: "/quality/change-register", label: "변경점 정보 등록" },
        {
          link: "/quality/change-list",
          label: t("menu.quality.change"),
          visible: customFunctions.ADD_CHANGE_LIST_MENU_SETTING,
        },
        // { link: "/quality/pre-warehouseList", label: "수입검사", visible: customFunctions.ADD_PRE_WAREHOUSE_LIST_MENU_SETTING },
      ],
    },
    {
      label: "KPI",
      visible: customFunctions.ADD_KPI_MENU_SETTING,
      links: [
        {
          link: "/kpi/leadtime",
          label: t("menu.kpi.leadtime"),
          visible:
            customFunctions.ADD_KPI_MENU_SETTING && !customFunctions.ADD_BTTELECOM_KPI_MENU_SETTING,
        },
        {
          link: "/kpi/manhour",
          label: t("menu.kpi.manhour"),
          visible:
            customFunctions.ADD_KPI_MENU_SETTING && !customFunctions.ADD_BTTELECOM_KPI_MENU_SETTING,
        },
        {
          link: "/kpi/defect",
          label: t("menu.kpi.defect"),
          visible: customFunctions.ADD_KPI_MENU_SETTING,
        },
        {
          link: "/kpi/leadtimeOrder",
          label: t("menu.kpi.leadtimeOrder"),
          visible:
            customFunctions.ADD_KPI_MENU_SETTING && !customFunctions.ADD_BTTELECOM_KPI_MENU_SETTING,
        },
        {
          link: "/kpi/powerUsage",
          label: t("menu.kpi.powerUsage"),
          visible:
            customFunctions.ADD_KPI_MENU_SETTING && !customFunctions.ADD_BTTELECOM_KPI_MENU_SETTING,
        },
        {
          link: "/kpi/uph",
          label: t("menu.kpi.uph"),
          visible:
            customFunctions.ADD_KPI_MENU_SETTING && !customFunctions.ADD_BTTELECOM_KPI_MENU_SETTING,
        },
        {
          link: "/kpi/rateOfOperation",
          label: t("menu.kpi.rateOfOperation"),
          visible: customFunctions.ADD_KPI_MENU_SETTING,
        },
        {
          link: "/dataDisplay",
          label: t("menu.kpi.dataDisplay"),
          visible: customFunctions.ADD_DATA_DISPLAY_MENU_SETTING,
        },
      ],
    },
    {
      label: t("menu.monitoring.monitoring"),
      visible: customFunctions.ADD_MONITORING_MENU_SETTING,
      links: [
        { link: "/overview", label: t("menu.monitoring.mes"), visible: true },
        { link: "/monitoring", label: t("menu.monitoring.monitoring"), visible: true },
        {
          link: "/equipmentAnalysis",
          label: t("menu.monitoring.equipment"),
          visible: customFunctions.ADD_DASHBOARD_EQUIPMENT_ANALYSIS,
        },
        {
          link: "/pressMonitoring/factoryMonitoring",
          label: t("menu.monitoring.pms"),
          visible: customFunctions.ADD_PMS_PRESS_MONITORING,
        },
      ],
    },
    {
      label: "PMS",
      visible: customFunctions.ADD_PMS_MENU_SETTING,
      links: [
        {
          link: "/monitoring",
          label: t("menu.pms.monitoring"),
          visible: true,
        },
        {
          link: "/pressMonitoring/factoryMonitoring",
          label: t("menu.pms.analysisMonitoring"),
          visible: true,
        },
        {
          link: "/press",
          label: t("menu.pms.press.management"),
          links: [
            { link: "/press/errorview", label: t("menu.pms.press.error"), visible: true },
            // { link: '/press/parameterview', label: '파라미터 보기', visible: true },
            // { link: '/press/camview', label: '캠 보기', visible: true },
            // { link: '/press/clutchandbrake', label: '클러치 & 브레이크', visible: true },
            { link: "/press/facilities", label: t("menu.pms.press.repair"), visible: true },
            {
              link: "/press/complete/admin",
              label: t("menu.pms.press.repairComplete"),
              visible: true,
            },
            { link: "/press/problem", label: t("menu.pms.press.problem"), visible: true },
            {
              link: "/press/PressDailyRegister",
              label: t("menu.pms.press.dailyRegister"),
              visible: true,
            },
            {
              link: "/press/PressDailyStatus",
              label: t("menu.pms.press.dailyStatus"),
              visible: true,
            },
          ],
          visible: true,
        },
        {
          link: "/analysis",
          label: t("menu.pms.analysis.analysis"),
          links: [
            { link: "/analysis/output", label: t("menu.pms.analysis.output"), visible: true },
            { link: "/analysis/ability", label: t("menu.pms.analysis.ability"), visible: true },
            // { link: '/analysis/totaloutput', label: '총생산량', visible: true },
            { link: "/analysis/error", label: t("menu.pms.analysis.error"), visible: true },
            { link: "/analysis/power", label: t("menu.pms.analysis.power"), visible: true },
            { link: "/analysis/idleTime", label: t("menu.pms.analysis.idleTime"), visible: true },
            { link: "/analysis/workTime", label: t("menu.pms.analysis.workTime"), visible: true },
          ],
          visible: true,
        },
        {
          link: "/moldManagement",
          label: t("menu.pms.mold.management"),
          links: [
            { link: "/mold/maintenance", label: t("menu.pms.mold.maintenance"), visible: true },
            { link: "/mold/repairs", label: t("menu.pms.mold.repair"), visible: true },
            {
              link: "/mold/complete/admin",
              label: t("menu.pms.mold.repairComplete"),
              visible: true,
            },
            { link: "/mold/problem", label: t("menu.pms.mold.problem"), visible: true },
            {
              link: "/mold/MoldDailyRegister",
              label: t("menu.pms.mold.dailyRegister"),
              visible: true,
            },
            { link: "/mold/MoldDailyStatus", label: t("menu.pms.mold.dailyStatus"), visible: true },
          ],
          visible: customFunctions.ADD_EQUIPMENT_MOLD_MENU_SETTING,
        },
      ],
    },
    {
      label: t("menu.pms.monitoring"),
      visible: customFunctions.ADD_EQUIPMENT_MONITORING_MENU_SETTING,
      links: [
        {
          label: t("menu.pms.monitoring"),
          links: [
            { link: "/overview", label: t("menu.monitoring.equipment"), visible: true },
            { link: "/monitoring", label: t("menu.monitoring.monitoring"), visible: true },
            {
              link: "/analysisMonitoring",
              label: t("menu.equipment.analysisMonitoring"),
              visible: true,
            },
          ],
          visible: true,
        },
        {
          label: t("menu.equipment.management.management"),
          links: [
            { link: "/errorView", label: t("menu.equipment.management.error"), visible: true },
            { link: "/maintenance", label: t("menu.equipment.management.repair"), visible: true },
            {
              link: "/maintenanceList",
              label: t("menu.equipment.management.repairList"),
              visible: true,
            },
            {
              link: "/maintenanceComplete",
              label: t("menu.equipment.management.repairComplete"),
              visible: true,
            },
            {
              link: "/equipmentProblem",
              label: t("menu.equipment.management.problem"),
              visible: true,
            },
            {
              link: "/dailyRegister",
              label: t("menu.equipment.management.dailyRegister"),
              visible: true,
            },
            {
              link: "/dailyList",
              label: t("menu.equipment.management.dailyStatus"),
              visible: true,
            },
          ],
          visible: true,
        },
        {
          label: t("menu.equipment.statistical"),
          links: [
            { link: "/output", label: t("menu.pms.analysis.output"), visible: true },
            { link: "/error", label: t("menu.pms.analysis.error"), visible: true },
            { link: "/power", label: t("menu.pms.analysis.power"), visible: true },
            { link: "/idleTime", label: t("menu.pms.analysis.idleTime"), visible: true },
            { link: "/workTime", label: t("menu.pms.analysis.workTime"), visible: true },
          ],
          visible: true,
        },
      ],
    },
    {
      label: "SCM",
      visible: customFunctions.ADD_SCM_MENU_SETTING,
      links: [
        {
          label: t("menu.scm.client.sales"),
          links: [
            { link: "/purchaseOrder", label: t("menu.scm.client.purchaseOrder"), visible: true },
            {
              link: "/purchaseOrderItems",
              label: t("menu.scm.client.purchaseOrderItem"),
              visible: true,
            },
            // { link: "/purchaseOrderItems-gyeongnam", label: "수주현황 (경남)", visible: true },
            // { link: "/deliveryStatus-gyeongnam", label: "납품정보등록 (경남)", visible: true },
            { link: "/deliveryStatus", label: t("menu.scm.client.delivery"), visible: true },
            // { link: "/deliveryList-gyeongnam", label: "납품현황 (경남)", visible: true },
          ],
          visible: true,
        },
        {
          label: t("menu.scm.partner.sales"),
          links: [
            // { link: "/order-gyeongnam", label: "발주등록 (경남)", visible: true },
            { link: "/order", label: t("menu.scm.partner.order"), visible: true },
            { link: "/orderItems", label: t("menu.scm.partner.orderItems"), visible: true },
            // { link: "/deliveryStatus-supplier", label: "납품정보등록 (협력사)", visible: true },
            // { link: "/deliveryList-gyeongnam", label: "납품현황 (경남)", visible: true },
            { link: "/deliveryStatus", label: t("menu.scm.partner.delivery"), visible: true },
          ],
          visible: true,
        },
        {
          label: t("menu.scm.partner.receiving"),
          links: [
            {
              link: "/shipment-request-supplier",
              label: t("menu.scm.partner.receivingRequest"),
              visible: true,
            },
            {
              link: "/warehousingRegister",
              label: t("menu.scm.partner.receivingReceipt"),
              visible: true,
            },
            {
              link: "/shipment-status-supplier",
              label: t("menu.scm.partner.receivingStatus"),
              visible: true,
            },
          ],
          visible: true,
        },
      ],
    },
    {
      label: t("menu.gps.gps"),
      visible: customFunctions.ADD_GPS_SYSTEM_MENU_SETTING,
      links: [
        {
          link: "http://taekang-tracker.sizl.co.kr/",
          label: t("menu.gps.tracking"),
          visible: true,
          target: "_blank",
        },
      ],
    },
  ];
  const [filteredLinks, setFilteredLinks] = useState<LinkType>(links);

  const updateLinks = () => {
    let filtered = links;

    if (userCode === "FLOUR" || userCode === "PB") {
      filtered = links.filter((link) => link.label === "SCM");

      if (userCode === "FLOUR") {
        filtered = filtered.map((link) => ({
          ...link,
          links: link.links?.filter((subLink) => subLink.label === "영업관리(협력사)"),
        }));
      }

      if (userCode === "PB") {
        filtered = filtered.map((link) => ({
          ...link,
          links: link.links?.filter((subLink) => subLink.label === "영업관리(고객사)"),
        }));
      }
    }

    const translatedLinks = filtered.map((link) => ({
      ...link,
      label: t(link.label),
      links: link.links?.map((subLink) => ({
        ...subLink,
        label: t(subLink.label),
      })),
    }));

    setFilteredLinks(translatedLinks);
  };

  useEffect(() => {
    updateLinks();
  }, [userCode, i18n.language, t]);

  return (
    <Flex gap="md" align={"center"} style={{ whiteSpace: "pre-wrap" }}>
      {renderMainMenuItems(filteredLinks)}
    </Flex>
  );
};
