import { customFunctions } from "@/config/customFunction";
import { MenuTemplate } from "@/features/ui/Menu";
import { useLogout } from "@/hooks";
import { theme } from "@/styles/theme";
import {
  ActionIcon,
  Button,
  Flex,
  Group,
  Header,
  Select,
  Title,
  useMantineColorScheme,
} from "@mantine/core";
import { IconMoonStars, IconSun } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

export function HeaderMenu() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === "dark";
  const logout = useLogout();

  const { i18n, t } = useTranslation();

  const changeLanguage = (value: string | undefined) => {
    i18n.changeLanguage(value);
  };

  return (
    <Header height="3.5rem">
      <Flex w="100%" h="100%" px="0.625rem" justify="space-between" align="center" gap="xs">
        <Title order={3}>SIZL MESv3</Title>
        {customFunctions.ADD_MULTI_LANGUAGE_SUPPORT && (
          <Select
            style={{
              width: "80px",
              textAlign: "center",
              height: "3.375rem",
              fontSize: "1.125rem",
              color: theme.colors?.blue?.[6],
            }}
            defaultValue={"ko"}
            data={[
              { value: "ko", label: "한국어" },
              { value: "en", label: "English" },
            ]}
            onChange={(value) => changeLanguage(value ?? "")} // onChange 이벤트 핸들러 추가
          />
        )}
        <Group spacing="md">
          <MenuTemplate />

          <Button onClick={logout} variant="subtle" color="gray">
            Logout
          </Button>
          <ActionIcon
            variant="outline"
            color={dark ? "yellow" : "blue"}
            onClick={() => toggleColorScheme()}
            title="Toggle color scheme"
          >
            {dark ? <IconSun size="1.1rem" /> : <IconMoonStars size="1.1rem" />}
          </ActionIcon>
        </Group>
        {/* <Burger opened={opened} onClick={toggle} size="sm" /> */}
      </Flex>
    </Header>
  );
}
